import React, { useState, useRef, useEffect } from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";

export interface MenuAction {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
  variant?: "default" | "error";
}

interface TableMenuProps {
  actions: MenuAction[];
}

const TableMenu: React.FC<TableMenuProps> = ({ actions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const getDropdownPosition = () => {
    if (!buttonRef.current) return {};
    const rect = buttonRef.current.getBoundingClientRect();

    return {
      top: `${rect.bottom}px`,
      left: `${rect.left}px`,
    };
  };

  return (
    <div ref={menuRef} className="relative inline-block">
      <button ref={buttonRef} className="btn btn-ghost btn-xs" onClick={() => setIsOpen(!isOpen)} title="Actions">
        <EllipsisVerticalIcon className="size-4" />
      </button>

      {isOpen && (
        <div className="fixed z-50 min-w-[200px] bg-base-100 border border-neutral rounded p-2 mt-1" style={getDropdownPosition()}>
          {actions.map((action, index) => (
            <button
              key={index}
              onClick={() => {
                action.onClick();
                setIsOpen(false);
              }}
              className={`w-full px-4 py-2 text-left hover:bg-neutral flex rounded items-center gap-2`}
            >
              {action.icon}
              <span>{action.label}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default TableMenu;
