import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import AppLayout from "./components/AppLayout";
import { ThemeProvider } from "./contexts/ThemeContext";
import ProtectedRoute from "./pages/ProtectedRoute";
import ToastContainer from "./components/ToastContainer";
import GoalsPage from "./pages/GoalsPage";
import UsersPage from "./pages/UsersPage";
import CreateUserPage from "./pages/CreateUserPage";
import EditUserPage from "./pages/EditUserPage";
import StudioPage from "./pages/StudioPage";
import GroupsPage from "./pages/GroupsPage";
import CreateGroupPage from "./pages/CreateGroupPage";
import EditGroupPage from "./pages/EditGroupPage";
import CreateGoalPage from "./pages/CreateGoalPage";
import GoalDetailsPage from "./pages/GoalsDetailPage";
import EditGoalPage from "./pages/EditGoalPage";
import { EditTaskPage } from "./pages/EditTaskPage";
import { CreateTaskPage } from "./pages/CreateTaskPage";
import ProfilePage from "./pages/ProfilePage";
import UserTasksPage from "./pages/UserTasksPage";

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<AppLayout />}>
              <Route index element={<GoalsPage />} />
              {/* USERS */}
              <Route path="members" element={<UsersPage />} />
              <Route path="members/create" element={<CreateUserPage />} />
              <Route path="members/edit/:id" element={<EditUserPage />} />
              {/* STUDIOS */}
              <Route path="studios" element={<StudioPage />} />
              {/* Groups */}
              <Route path="groups" element={<GroupsPage />} />
              <Route path="groups/create" element={<CreateGroupPage />} />
              <Route path="groups/edit/:id" element={<EditGroupPage />} />
              {/* Goals */}
              <Route path="goals" element={<GoalsPage />} />
              <Route path="goals/create" element={<CreateGoalPage />} />
              <Route path="goals/details/:id" element={<GoalDetailsPage />} />
              <Route path="goals/edit/:id" element={<EditGoalPage />} />
              {/* Goal Tasks */}
              <Route path="goals/task/edit/:goalId/:taskId" element={<EditTaskPage />} />
              <Route path="goals/task/create/:goalId" element={<CreateTaskPage />} />
              {/* Profile */}
              <Route path="profile" element={<ProfilePage />} />
              {/* User Tasks */}
              <Route path="my-tasks" element={<UserTasksPage />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
