import { makeAutoObservable, runInAction } from "mobx";
import { apiService } from "../ApiService";
import { CustomerDTO, StudioDTO } from "../backend-types";
import { API_CONFIG } from "../config/api.config";
import { RootStore } from "./RootStore";

export class CustomerStore {
  customer: CustomerDTO | null = null;
  studios: StudioDTO[] = [];
  isLoading: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  initializeCustomer(customerData: CustomerDTO) {
    runInAction(() => {
      this.customer = customerData;
      this.studios = customerData.studios || [];
    });
  }

  async refreshCustomer() {
    if (!this.customer?.id) {
      console.error("Error while loading data: no customer available in data store");
      throw new Error("Fehler beim Laden der Daten!");
    }

    this.isLoading = true;
    try {
      const updatedCustomer = await apiService.get<CustomerDTO>(`${API_CONFIG.ENDPOINTS.CUSTOMERS_GET}/${this.customer.id}`);
      runInAction(() => {
        this.customer = updatedCustomer;
        this.studios = updatedCustomer.studios || [];
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
      });
      throw error;
    }
  }

  getStudio(studioId: number) {
    return this.studios.find((studio) => studio.id === studioId);
  }

  clear() {
    runInAction(() => {
      this.customer = null;
      this.studios = [];
      this.isLoading = false;
    });
  }

  get studioCount() {
    return this.studios.length;
  }
}
