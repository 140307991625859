import { useNavigate } from "react-router-dom";

const SidebarHeader = () => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center space-x-3 p-4 bg-base-100 rounded-lg cursor-pointer" onClick={() => navigate("/goals")}>
      <img className="h-8" src="./icon.png" alt="Logo" />
      <div className="flex">
        <span className="text-xl font-semibold">Studio</span>
        <span className="text-xl text-primary font-semibold">.OS</span>
      </div>
    </div>
  );
};

export default SidebarHeader;
