import { makeAutoObservable, runInAction } from "mobx";
import { apiService } from "../ApiService";
import { DateTime } from "luxon";
import { GoalCreateDTO, GoalDTO, GoalUpdateDTO, StudioCancellationMetricsProjection, StudioMetricsProjection } from "../backend-types";
import { RootStore } from "./RootStore";
import { API_CONFIG } from "../config/api.config";

export interface MetricsState {
  isLoading: boolean;
  error: string | null;
  value: number | null;
  rawData?: StudioMetricsProjection[] | StudioCancellationMetricsProjection[];
}

// TODO: Move this to backend-types.ts and remove it from here
export enum MetricType {
  NEW_MEMBERS = "NEW_MEMBERS",
  CANCELLATIONS = "CANCELLATIONS",
}

export class GoalStore {
  goals: GoalDTO[] = [];
  isLoading: boolean = false;
  error: string | null = null;
  metricsState: Map<string, MetricsState> = new Map();

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  private initializeMetricsState(goalId: string) {
    if (!this.metricsState.has(goalId)) {
      this.metricsState.set(goalId, {
        isLoading: false,
        error: null,
        value: null,
      });
    }
  }

  private calculateMetricValue(data: StudioMetricsProjection[] | StudioCancellationMetricsProjection[], metricType: string): number {
    if (!data || data.length === 0) return 0;
    const latestData = data[data.length - 1];

    switch (metricType) {
      case "NEW_MEMBERS":
        return (latestData as StudioMetricsProjection).runningTotal;
      case "CANCELLATIONS":
        return (latestData as StudioCancellationMetricsProjection).runningTotal;
      default:
        return 0;
    }
  }

  async loadMetricsForGoal(goalId: string, metricType: string) {
    this.initializeMetricsState(goalId);
    const goal = this.goals.find((g) => g.id === goalId);
    if (!goal) return;

    runInAction(() => {
      this.metricsState.set(goalId, {
        ...this.metricsState.get(goalId)!,
        isLoading: true,
        error: null,
      });
    });

    try {
      const endpoint = metricType === "NEW_MEMBERS" ? API_CONFIG.ENDPOINTS.METRICS_NEW_MEMBERS : API_CONFIG.ENDPOINTS.METRICS_CANCELLATIONS;

      // Determine whether to use studioId or groupId in the URL
      let queryParams = `startDate=${goal.startDate}&endDate=${goal.endDate}`;
      if (goal.studioId && goal.studioId !== -1) {
        queryParams += `&studioId=${goal.studioId}`;
      } else if (goal.groupId) {
        queryParams += `&studioGroupId=${goal.groupId}`;
      }

      const url = `${endpoint}?${queryParams}`;

      const response = await apiService.get<StudioMetricsProjection[] | StudioCancellationMetricsProjection[]>(url);

      const metricValue = this.calculateMetricValue(response, metricType);

      runInAction(() => {
        this.metricsState.set(goalId, {
          isLoading: false,
          error: null,
          value: metricValue,
          rawData: response,
        });
      });
    } catch (error) {
      console.error("Request failed:", error);
      runInAction(() => {
        this.metricsState.set(goalId, {
          ...this.metricsState.get(goalId)!,
          isLoading: false,
          error: "ERROR",
          value: null,
        });
      });
    }
  }

  async createGoal(dto: GoalCreateDTO) {
    try {
      const response = await apiService.post<GoalDTO>(API_CONFIG.ENDPOINTS.GOALS_CREATE, dto);
      runInAction(() => {
        this.goals.push(response);
        this.error = null;
      });

      await this.loadMetricsForGoal(response.id, response.metricType);

      return response;
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Erstellen des Ziels";
      });
      throw error;
    }
  }

  async deleteGoal(id: string) {
    try {
      await apiService.delete(`${API_CONFIG.ENDPOINTS.GOALS_DELETE}/${id}`);
      runInAction(() => {
        this.goals = this.goals.filter((goal) => goal.id !== id);
      });
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Löschen des Ziels";
      });
      throw error;
    }
  }

  async getGoal(id: string) {
    try {
      const response = await apiService.get<GoalDTO>(`${API_CONFIG.ENDPOINTS.GOALS_GET}/${id}`);

      if (!response) {
        throw new Error("Goal not found");
      }

      // Convert dates from strings to Date objects
      runInAction(() => {
        response.startDate = new Date(response.startDate);
        response.endDate = new Date(response.endDate);
      });

      return response;
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Laden des Ziels";
      });
      throw error;
    }
  }

  async updateGoal(id: string, updateData: GoalUpdateDTO) {
    try {
      const response = await apiService.put<GoalDTO>(`${API_CONFIG.ENDPOINTS.GOALS_UPDATE}/${id}`, updateData);

      runInAction(() => {
        // Update the goal in the goals array
        const index = this.goals.findIndex((g) => g.id === id);
        if (index !== -1) {
          this.goals[index] = {
            ...this.goals[index],
            ...response,
          };
        }
        this.error = null;
      });

      return response;
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Aktualisieren des Ziels";
      });
      throw error;
    }
  }

  getMetricsState(goalId: string): MetricsState {
    this.initializeMetricsState(goalId);
    return this.metricsState.get(goalId)!;
  }

  async refreshGoals() {
    this.isLoading = true;
    try {
      const response = await apiService.get<GoalDTO[]>(API_CONFIG.ENDPOINTS.GOALS_ALL);
      runInAction(() => {
        this.goals = response;
        this.isLoading = false;
        this.error = null;

        response.forEach((goal) => {
          this.loadMetricsForGoal(goal.id, goal.metricType);
        });
      });
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Laden der Ziele";
        this.isLoading = false;
      });
      throw error;
    }
  }

  get plannedGoals() {
    const now = DateTime.now();
    return this.goals.filter((goal) => DateTime.fromISO(goal.startDate.toString()) > now);
  }

  get activeGoals() {
    const now = DateTime.now();
    return this.goals.filter((goal) => DateTime.fromISO(goal.startDate.toString()) <= now && DateTime.fromISO(goal.endDate.toString()) >= now);
  }

  get completedGoals() {
    const now = DateTime.now();
    return this.goals.filter((goal) => DateTime.fromISO(goal.endDate.toString()) < now);
  }

  clearError() {
    this.error = null;
  }

  resetStore() {
    this.goals = [];
    this.isLoading = false;
    this.error = null;
    this.metricsState.clear();
  }
}
