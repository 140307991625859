import React from "react";

interface ProgressBarProps {
  current: number;
  goal: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ current, goal }) => {
  // Calculate percentage
  const percentage = Math.min(Math.round((current / goal) * 100), 100);

  // Dynamic color calculation based on percentage
  const getProgressColor = (percent: number) => {
    if (percent < 30) return "bg-red-500";
    if (percent < 70) return "bg-yellow-500";
    return "bg-green-500";
  };

  // Dynamic font-color calculation based on percentage
  const getTextColor = (percent: number) => {
    return percent < 50 ? "text-white" : "text-black";
  };

  // Get background color class
  const colorClass = getProgressColor(percentage);

  return (
    <div className="w-full space-y-1">
      {/* Progress bar container with centered percentage */}
      <div className="relative w-full h-6 rounded">
        {/* Actual progress bar */}
        <div className={`h-full ${colorClass} rounded transition-all duration-500 ease-in-out`} style={{ width: `${percentage}%` }} />
        {/* Centered percentage text */}
        <div className="absolute inset-0 flex items-center justify-center">
          <span className={`text-xs ${getTextColor(percentage)}`}>{percentage}%</span>
        </div>
      </div>

      {/* Values below the bar */}
      <div className="flex justify-between text-xs  ">
        <span>{current.toFixed(0)} (ist)</span>
        <span>{goal.toFixed(0)} (soll)</span>
      </div>
    </div>
  );
};

export default ProgressBar;
