import { makeAutoObservable, runInAction } from "mobx";
import { apiService } from "../ApiService";
import { API_CONFIG } from "../config/api.config";
import { RootStore } from "./RootStore";
import { UpdatePasswordRequestDTO, UserCreationDTO, UserInformationDTO, UserUpdateDTO } from "../backend-types";

export class UserStore {
  users: UserInformationDTO[] = [];
  isLoading: boolean = false;
  error: string | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  async refreshUsers() {
    this.isLoading = true;
    try {
      const response = await apiService.get<UserInformationDTO[]>(API_CONFIG.ENDPOINTS.USERS_ALL);
      runInAction(() => {
        this.users = response;
        this.isLoading = false;
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Laden der Benutzer";
        this.isLoading = false;
      });
      throw error;
    }
  }

  async getUser(id: string): Promise<UserInformationDTO> {
    try {
      const response = await apiService.get<UserInformationDTO>(`${API_CONFIG.ENDPOINTS.USERS_GET}/${id}`);
      runInAction(() => {
        const index = this.users.findIndex((u) => u.id === id);
        if (index === -1) {
          this.users.push(response);
        } else {
          this.users[index] = response;
        }
        this.error = null;
      });
      const user = response;

      if (!user) {
        throw new Error("User not found");
      }

      return user;
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Laden des Benutzers";
      });
      throw error;
    }
  }

  async createUser(createData: UserCreationDTO) {
    try {
      const response = await apiService.post(API_CONFIG.ENDPOINTS.USERS_CREATE, createData);
      await this.refreshUsers();
      return response;
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Erstellen des Benutzers";
      });
      throw error;
    }
  }

  async updateUser(id: string, updateData: UserUpdateDTO) {
    try {
      const response = await apiService.put(`${API_CONFIG.ENDPOINTS.USERS_UPDATE}/${id}`, updateData);
      await this.refreshUsers();
      return response;
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Aktualisieren des Benutzers";
      });
      throw error;
    }
  }

  async deleteUser(id: string) {
    try {
      await apiService.delete(`${API_CONFIG.ENDPOINTS.USERS_DELETE}/${id}`);
      runInAction(() => {
        this.users = this.users.filter((user) => user.id !== id);
      });
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Löschen des Benutzers";
      });
      throw error;
    }
  }

  async changePassword(data: UpdatePasswordRequestDTO) {
    try {
      await apiService.put(API_CONFIG.ENDPOINTS.USERS_CHANGE_PASSWORD, data);
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Ändern des Passworts";
      });
      throw error;
    }
  }

  getUserName(userId: string): string {
    const user = this.users.find((u) => u.id === userId);
    if (!user) return "Unbekannt";
    return `${user.firstname} ${user.lastname}`;
  }

  clearError() {
    this.error = null;
  }

  resetStore() {
    this.users = [];
    this.isLoading = false;
    this.error = null;
  }
}
