import { PencilIcon, TrashIcon, UserIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { TaskDTO } from "../backend-types";
import TableMenu, { MenuAction } from "../components/MenuAction";
import { useConfirmationModal } from "../components/ConfirmationModal";
import Badge from "../components/Badge";
import { useNavigate } from "react-router-dom";

type TaskFilter = "all" | "completed" | "open";

interface TaskTableProps {
  tasks: TaskDTO[];
  goalId?: string;
  onToggleTask: (taskId: string) => void;
  onEditTask?: (taskId: string) => void;
  onDeleteTask?: (taskId: string) => void;
  getUserName: (userId: string) => string;
  getGoalName: (goalId: string) => string;
  showAddButton?: boolean;
  showControls?: boolean;
  onAddTask?: () => void;
  addTaskComponent?: React.ReactNode;
  filter?: TaskFilter;
  onFilterChange?: (filter: TaskFilter) => void;
  hideFilter?: boolean;
}

const TaskTable: React.FC<TaskTableProps> = ({
  tasks,
  goalId,
  onToggleTask,
  onEditTask,
  onDeleteTask,
  getUserName,
  getGoalName,
  showAddButton = true,
  showControls = true,
  onAddTask,
  addTaskComponent,
  filter: externalFilter,
  onFilterChange,
  hideFilter,
}) => {
  const [internalFilter, setInternalFilter] = useState<TaskFilter>("all");
  const { showConfirmationModal, ConfirmationModalComponent } = useConfirmationModal();
  const navigate = useNavigate();

  // Use external filter if provided, otherwise use internal
  const currentFilter = externalFilter ?? internalFilter;

  const handleFilterChange = (newFilter: TaskFilter) => {
    if (onFilterChange) {
      onFilterChange(newFilter);
    } else {
      setInternalFilter(newFilter);
    }
  };

  const filteredTasks = tasks.filter((task) => {
    switch (currentFilter) {
      case "completed":
        return task.completed;
      case "open":
        return !task.completed;
      default:
        return true;
    }
  });

  const handleDeleteTask = async (task: TaskDTO) => {
    const confirmed = await showConfirmationModal({
      title: "Aufgabe löschen",
      text: `Möchten Sie die folgende Aufgabe wirklich löschen?\n\n${task.title}`,
      confirmText: "Löschen",
      cancelText: "Abbrechen",
    });

    if (confirmed && onDeleteTask) {
      onDeleteTask(task.id);
    }
  };

  const getMenuActions = (task: TaskDTO): MenuAction[] => {
    const actions: MenuAction[] = [];

    if (onEditTask) {
      actions.push({
        label: "Bearbeiten",
        icon: <PencilIcon className="size-4" />,
        onClick: () => onEditTask(task.id),
      });
    }

    if (onDeleteTask) {
      actions.push({
        label: "Löschen",
        icon: <TrashIcon className="size-4" />,
        onClick: () => handleDeleteTask(task),
        variant: "error",
      });
    }

    return actions;
  };

  return (
    <div className="border border-neutral rounded p-4 flex flex-col h-full">
      <ConfirmationModalComponent />
      <div className="flex justify-between items-center pb-4 border-b border-neutral">
        <div className="text-xl font-bold">Aufgaben</div>
        <div className="flex gap-4 items-center">
          {!hideFilter && (
            <select value={currentFilter} onChange={(e) => handleFilterChange(e.target.value as TaskFilter)} className="select select-sm select-bordered">
              <option value="all">Alle Aufgaben</option>
              <option value="completed">Erledigt</option>
              <option value="open">Offen</option>
            </select>
          )}
          {showAddButton && goalId && (
            <button className="btn btn-primary btn-xs" onClick={() => navigate(`/goals/task/create/${goalId}`)}>
              Aufgabe erstellen
            </button>
          )}
        </div>
      </div>
      <div className="overflow-y-auto flex-1">
        <table className="table w-full table-fixed">
          <thead className="sticky top-0 bg-base-100 z-10">
            <tr className="border-b border-neutral">
              <th className="text-left py-2 w-[45%]">Aufgabe</th>
              <th className="text-left py-2 w-[15%]">Owner</th>
              <th className="text-left py-2 w-[15%]">Ziel</th>
              {showControls && <th className="text-left py-2 w-[5%]"></th>}
            </tr>
          </thead>
          <tbody>
            {filteredTasks.map((task) => (
              <tr key={task.id} className="border-b border-neutral">
                <td className="py-2">
                  <div className="flex items-center gap-2">
                    <input type="checkbox" checked={task.completed} onChange={() => onToggleTask(task.id)} className="checkbox checkbox-sm flex-shrink-0" />
                    <div>
                      <span className={`${task.completed ? "line-through text-neutral-500" : ""} break-words break-all`} title={task.title}>
                        {task.title}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="py-2">
                  <div className="flex items-center gap-2">
                    <UserIcon className="size-4 flex-shrink-0" />
                    <span className="truncate">{getUserName(task.ownerId)}</span>
                  </div>
                </td>
                <td className="py-2">
                  <Badge text={getGoalName(task.goalId)} />
                </td>
                {showControls && <td className="py-2">{(onEditTask || onDeleteTask) && <TableMenu actions={getMenuActions(task)} />}</td>}
              </tr>
            ))}
            {showAddButton && goalId && (
              <tr className="border-b border-neutral last:border-b-0">
                <td colSpan={showControls ? 4 : 3} className="py-2">
                  {addTaskComponent || (
                    <button onClick={onAddTask} className="flex items-center gap-2 text-neutral-500 hover:text-neutral-700">
                      <span className="text-lg">+</span>
                      <span>Aufgabe hinzufügen</span>
                    </button>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TaskTable;
