import React, { useCallback, useState } from "react";

interface ConfirmationModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  text: string;
  confirmText?: string;
  cancelText?: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ title, text, isOpen, confirmText = "Confirm", cancelText = "Cancel", onConfirm, onCancel }) => {
  return (
    <>
      <input type="checkbox" id="leave-confirmation-modal" className="modal-toggle" checked={isOpen} onChange={() => {}} />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">{title}</h3>
          <p className="py-4">{text}</p>
          <div className="modal-action">
            <button className="btn btn-error" onClick={onConfirm}>
              {confirmText}
            </button>
            <button className="btn" onClick={onCancel}>
              {cancelText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export function useConfirmationModal() {
  const [modalState, setModalState] = useState<ConfirmationModalProps | null>(null);

  const showConfirmationModal = useCallback((props: Omit<ConfirmationModalProps, "isOpen" | "onConfirm" | "onCancel">): Promise<boolean> => {
    return new Promise((resolve) => {
      setModalState({
        ...props,
        isOpen: true,
        onConfirm: () => {
          setModalState(null);
          resolve(true);
        },
        onCancel: () => {
          setModalState(null);
          resolve(false);
        },
      });
    });
  }, []);

  const ConfirmationModalComponent = useCallback(() => {
    if (!modalState) return null;
    return <ConfirmationModal {...modalState} />;
  }, [modalState]);

  return { showConfirmationModal, ConfirmationModalComponent };
}

export default ConfirmationModal;
