import { BoltIcon, CalendarIcon, EyeIcon, FlagIcon, InformationCircleIcon, PencilIcon, TrashIcon, UserIcon } from "@heroicons/react/24/outline";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoalDTO, StudioMetricsProjection } from "../backend-types";
import Badge from "../components/Badge";
import { useConfirmationModal } from "../components/ConfirmationModal";
import EmptyState from "../components/EmptyState";
import TableMenu, { MenuAction } from "../components/MenuAction";
import MetricCard from "../components/MetricCard";
import ProgressBar from "../components/ProgressBar";
import { toastStore } from "../components/ToastContainer";
import { MetricType } from "../stores/GoalStore";
import { rootStore } from "../stores/RootStore";
import { handleError } from "../errorHandler";

const GoalsPage: React.FC = observer(() => {
  const { goalStore, userStore } = rootStore;
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useState<"all" | "planned" | "active" | "done">("all");
  const { showConfirmationModal, ConfirmationModalComponent } = useConfirmationModal();

  // TODO: Add pagination component
  // const totalPages = Math.ceil(goalStore.totalGoals / goalStore.pageSize);
  // const currentPage = goalStore.currentPage;

  useEffect(() => {
    goalStore.refreshGoals();
    userStore.refreshUsers();
    // TODO: remove me after prod. test
    console.log("useEffect ran");
  }, [goalStore, userStore]);

  const handleRefreshGoals = () => {
    goalStore.refreshGoals();
  };

  const getMetricTypeLabel = (type: MetricType) => {
    switch (type) {
      case MetricType.NEW_MEMBERS:
        return "Neumitglieder";
      case MetricType.CANCELLATIONS:
        return "Kündigungen";
      default:
        return type;
    }
  };

  const calculateDaysRemaining = (endDate: string) => {
    const end = DateTime.fromISO(endDate);
    const today = DateTime.now().startOf("day");
    const diffDays = end.diff(today, "days").days;
    return Math.ceil(Math.max(diffDays, 0));
  };

  const formatDate = (date: string) => {
    return DateTime.fromISO(date).toLocaleString({ day: "2-digit", month: "2-digit", year: "numeric" });
  };

  const getFilteredGoals = (sortDirection: "asc" | "desc" = "asc") => {
    let goals;
    switch (selectedFilter) {
      case "planned":
        goals = goalStore.plannedGoals;
        break;
      case "active":
        goals = goalStore.activeGoals;
        break;
      case "done":
        goals = goalStore.completedGoals;
        break;
      default:
        goals = goalStore.goals;
    }

    return goals.slice().sort((a, b) => {
      const dateA = new Date(a.endDate).getTime();
      const dateB = new Date(b.endDate).getTime();
      return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
    });
  };

  const getMenuActions = (goal: GoalDTO): MenuAction[] => [
    {
      label: "Bearbeiten",
      icon: <PencilIcon className="size-4" />,
      onClick: () => navigate(`/goals/edit/${goal.id}`),
    },
    {
      label: "Löschen",
      icon: <TrashIcon className="size-4" />,
      onClick: () => handleDeleteGoal(goal),
      variant: "error",
    },
  ];

  const handleDeleteGoal = async (goal: GoalDTO) => {
    const confirmed = await showConfirmationModal({
      title: "Ziel löschen",
      text: `Möchten Sie das Ziel "${goal.description}" wirklich löschen?`,
      confirmText: "Löschen",
      cancelText: "Abbrechen",
    });

    if (confirmed) {
      try {
        await goalStore.deleteGoal(goal.id);
        toastStore.add({
          type: "success",
          caption: "Erfolg",
          message: "Ziel wurde erfolgreich gelöscht",
        });
      } catch (error: any) {
        const { type, message } = handleError(error, "Ziel löschen");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to delete goal:", error);
      }
    }
  };

  const renderGoalRow = (goal: GoalDTO) => (
    <tr key={goal.id} className="border-b border-neutral last:border-b-0">
      {/* DESC*/}
      <td>
        <div className="flex-grow min-w-0 mr-2 cursor-pointer" onClick={() => navigate("/goals/details/" + goal.id)}>
          <div className="text-sm truncate" title={goal.description || ""}>
            {goal.description || "KEINE BESCHREIBUNG"}
          </div>
        </div>
      </td>
      {/* KENNZAHL BADGE */}
      <td>
        <div className="flex gap-2">
          <Badge text={getMetricTypeLabel(goal.metricType as MetricType)} />
          <InformationCircleIcon className="size-5" />
        </div>
      </td>
      {/* Fortschritt */}
      <td>
        {(() => {
          if (!goal.groupId && !goal.studioId) {
            return <div className="text-red-400">Zuordnung fehlt/gelöscht</div>;
          }

          const metricsState = goalStore.getMetricsState(goal.id);

          if (metricsState.isLoading) {
            return (
              <div className="flex items-center justify-center h-6">
                <div className="loading loading-spinner loading-sm"></div>
              </div>
            );
          }

          if (metricsState.error) {
            return <div className="text-error font-medium">ERROR</div>;
          }

          // Get the latest runningTotal from the metrics data
          const rawData = metricsState.rawData as StudioMetricsProjection[];
          if (!rawData || rawData.length === 0) return <div>No data</div>;

          // Get the last non-zero running total
          const current = rawData.filter((d) => d.runningTotal > 0).slice(-1)[0]?.runningTotal || 0;

          const displayCurrent = goal.metricType === MetricType.CANCELLATIONS ? Math.max(0, goal.targetValue - current) : current;

          return (
            <div className="flex gap-2">
              <ProgressBar current={displayCurrent} goal={goal.targetValue} />
              <InformationCircleIcon className="size-5" title={`${current} of ${goal.targetValue} ${getMetricTypeLabel(goal.metricType as MetricType)}`} />
            </div>
          );
        })()}
      </td>
      {/* Deadend */}
      <td>
        <div className="flex flex-col gap-2">
          <span className="text-sm">{formatDate(goal.endDate.toString())}</span>
          <span className="text-xs opacity-50">Noch {calculateDaysRemaining(goal.endDate.toString())} Tage</span>
        </div>
      </td>
      {/* Owner */}
      <td>
        <div className="flex items-center gap-2">
          <UserIcon className="size-4" />
          <span className="text-sm">{goal.ownerId ? userStore.getUserName(goal.ownerId) : "Nicht zugewiesen"}</span>
        </div>
      </td>
      {/* CONTROLLS */}
      <td>
        <TableMenu actions={getMenuActions(goal)} />
      </td>
    </tr>
  );

  return (
    <div className="flex h-full w-full justify-center">
      <ConfirmationModalComponent />
      <div className="flex flex-col mt-4 gap-4 w-10/12 h-full">
        {/* PAGE HEADER */}
        <div className="flex justify-between items-center">
          <span className="text-2xl font-bold">Ziele</span>
          <button className="btn btn-primary btn-s" onClick={() => navigate("/goals/create")}>
            <p>Ziel festlegen</p>
          </button>
        </div>
        {/* Stats cards */}
        <div className="flex w-full justify-between gap-8">
          <MetricCard
            key={"allGoals"}
            icon={EyeIcon}
            title="Alle Ziele"
            value={goalStore.goals.length}
            textColor={"black"}
            bgColor="white"
            iconBgColor="primary"
            iconColor="white"
            onClick={() => setSelectedFilter("all")}
          />
          <MetricCard
            key={"plannedGoals"}
            icon={CalendarIcon}
            title="Geplant"
            value={goalStore.plannedGoals?.length || 0}
            textColor="white"
            bgColor="primary"
            iconBgColor="white"
            iconColor="primary"
            onClick={() => setSelectedFilter("planned")}
          />
          <MetricCard
            key={"activeGoals"}
            icon={BoltIcon}
            title="Aktiv"
            value={goalStore.activeGoals?.length || 0}
            textColor="white"
            bgColor="primary"
            iconBgColor="white"
            iconColor="primary"
            onClick={() => setSelectedFilter("active")}
          />
          <MetricCard
            key={"doneGoals"}
            icon={FlagIcon}
            title="Fertig"
            value={goalStore.completedGoals?.length || 0}
            textColor="white"
            bgColor="primary"
            iconBgColor="white"
            iconColor="primary"
            onClick={() => setSelectedFilter("done")}
          />
        </div>
        {/* FILTER SECTION*/}
        <div className="flex gap-4 w-full items-center">
          {/* Track Status Dropdown */}
          <select className="select select-bordered w-48" value={selectedFilter} onChange={(e) => setSelectedFilter(e.target.value as "all" | "planned" | "active" | "done")}>
            <option value="all">Alle Ziele</option>
            <option value="planned">Geplant</option>
            <option value="active">Aktiv</option>
            <option value="done">Fertig</option>
          </select>
        </div>
        {/* TABLE */}
        <div className="flex flex-col border border-neutral rounded p-4">
          <span className="text-xl font-bold pb-4 border-b border-neutral">Alle Ziele</span>
          {getFilteredGoals().length ? (
            <div className="flex overflow-auto">
              {/* TODO: Use loading spinner */}
              {goalStore.isLoading ? (
                <div className="text-center">Loading...</div>
              ) : (
                <table className="table w-full">
                  <colgroup>
                    <col className="w-[20%]" />
                    <col className="w-[10%]" />
                    <col className="w-[25%]" />
                    <col className="w-[10%]" />
                    <col className="w-[10%]" />
                    <col className="w-[5%]" />
                  </colgroup>
                  <thead>
                    <tr className="border-b border-neutral">
                      <th className="text-left">Name</th>
                      <th className="text-left">Kennzahl</th>
                      <th className="text-left">Fortschritt</th>
                      <th className="text-left">Deadline</th>
                      <th className="text-left">Owner</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{getFilteredGoals().map(renderGoalRow)}</tbody>
                </table>
              )}
            </div>
          ) : (
            <EmptyState message={"Keine Ziele verfügbar"} onRefresh={handleRefreshGoals} />
          )}
        </div>
      </div>
    </div>
  );
});

export default GoalsPage;
