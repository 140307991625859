import { makeAutoObservable, runInAction } from "mobx";
import { apiService } from "../ApiService";
import { GroupDTO } from "../backend-types";
import { API_CONFIG } from "../config/api.config";
import { RootStore } from "./RootStore";

export class GroupStore {
  groups: GroupDTO[] = [];
  totalGroups: number = 0;
  isLoading: boolean = false;
  error: string | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  async refreshGroups() {
    this.isLoading = true;
    try {
      const response = await apiService.get<GroupDTO[]>(API_CONFIG.ENDPOINTS.GROUPS_ALL);
      runInAction(() => {
        this.groups = response;
        this.totalGroups = response.length;
        this.isLoading = false;
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Laden der Gruppen";
        this.isLoading = false;
      });
    }
  }

  async getGroup(id: string) {
    try {
      const response = await apiService.get<GroupDTO>(`${API_CONFIG.ENDPOINTS.GROUPS_GET}/${id}`);
      runInAction(() => {
        const index = this.groups.findIndex((g) => g.id === id);
        if (index === -1) {
          this.groups.push(response);
        } else {
          this.groups[index] = response;
        }
        this.error = null;
      });
      return response;
    } catch (error: any) {
      runInAction(() => {
        this.error = "Fehler beim Laden der Gruppe";
      });
      throw error;
    }
  }

  async createGroup(createData: { groupName: string; studioIds: number[] }) {
    const response = await apiService.post(API_CONFIG.ENDPOINTS.GROUPS_CREATE, createData);
    await this.refreshGroups();
    return response;
  }

  async updateGroup(updateData: { id: string; groupName: string; studioIds: number[] }) {
    const response = await apiService.put(`${API_CONFIG.ENDPOINTS.GROUPS_UPDATE}`, updateData);
    await this.refreshGroups();
    return response;
  }

  async deleteGroup(id: string) {
    try {
      await apiService.delete(API_CONFIG.ENDPOINTS.GROUPS_DELETE + `/${id}`);
      runInAction(() => {
        this.groups = this.groups.filter((group) => group.id !== id);
        this.totalGroups = this.groups.length;
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = "Fehler beim Löschen der Gruppe";
      });
      throw error;
    }
  }

  clearError() {
    this.error = null;
  }

  resetStore() {
    this.groups = [];
    this.totalGroups = 0;
    this.isLoading = false;
    this.error = null;
  }
}
