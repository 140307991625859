import React from "react";
import RefreshButton from "./RefreshButton";

interface EmptyStateProps {
  message: string;
  onRefresh: () => void;
}

const EmptyState: React.FC<EmptyStateProps> = ({ message, onRefresh }) => {
  return (
    <div className="flex flex-col items-center justify-center h-32 rounded-lg">
      <p className="text-md mb-4">{message}</p>
      <RefreshButton onClick={onRefresh} />
    </div>
  );
};

export default EmptyState;
