import { AxiosError } from "axios";

interface ErrorResponse {
  message?: string;
  [key: string]: any;
}

interface NormalizedError {
  status?: number;
  message: string;
  details?: any;
}

interface ErrorDetails {
  type: "error" | "warning" | "info";
  message: string;
}

const ERROR_MAPPINGS: Record<number, ErrorDetails> = {
  400: { type: "error", message: "Ungültige Anfrage" },
  401: { type: "warning", message: "Nicht autorisiert - Bitte melden Sie sich erneut an" },
  403: { type: "error", message: "Keine Berechtigung für diese Aktion" },
  404: { type: "error", message: "Ressource nicht gefunden" },
  422: { type: "error", message: "Validierungsfehler" },
  503: { type: "warning", message: "Service nicht verfügbar" },
};

export const handleError = (error: unknown, context?: string): ErrorDetails => {
  const normalizedError: NormalizedError = normalizeError(error);

  const errorDetails = normalizedError.status ? ERROR_MAPPINGS[normalizedError.status] : { type: "error" as const, message: normalizedError.message };

  return {
    type: errorDetails.type,
    message: context ? `${context}: ${errorDetails.message}` : errorDetails.message,
  };
};

const normalizeError = (error: unknown): NormalizedError => {
  if (error instanceof Error) {
    if ((error as AxiosError).isAxiosError) {
      const axiosError = error as AxiosError<ErrorResponse>;
      return {
        status: axiosError.response?.status,
        message: axiosError.response?.data?.message || axiosError.message,
        details: axiosError.response?.data,
      };
    }
    return {
      message: error.message,
    };
  }

  return {
    message: "Ein unbekannter Fehler ist aufgetreten",
  };
};
