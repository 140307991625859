import React, { useState, useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { rootStore } from "../stores/RootStore";
import { useConfirmationModal } from "../components/ConfirmationModal";
import { toastStore } from "../components/ToastContainer";
import { DateTime } from "luxon";
import { MetricType } from "../stores/GoalStore";
import { GoalCreateDTO } from "../backend-types";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { handleError } from "../errorHandler";

const INITIAL_GOAL_STATE: GoalCreateDTO = {
  startDate: new Date(),
  endDate: new Date(DateTime.now().plus({ months: 1 }).toISO()),
  targetValue: 0,
  metricType: MetricType.NEW_MEMBERS,
  studioId: -1,
  groupId: "",
  description: "",
  ownerId: "",
} as const;

const CreateGoalPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const { goalStore, userStore, customerStore, groupStore } = rootStore;
  const [selectedType, setSelectedType] = useState<"studio" | "group" | null>(null);
  const { showConfirmationModal, ConfirmationModalComponent } = useConfirmationModal();

  const [goal, setGoal] = useState<GoalCreateDTO>(INITIAL_GOAL_STATE);

  useEffect(() => {
    userStore.refreshUsers();
    customerStore.refreshCustomer();
    groupStore.refreshGroups();
  }, [userStore, customerStore, groupStore]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setGoal((prev) => ({
      ...prev,
      [name]: name === "targetValue" || name === "studioId" ? parseInt(value) || 0 : name === "startDate" || name === "endDate" ? new Date(value) : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await goalStore.createGoal(goal);
      toastStore.add({
        type: "success",
        caption: "Erfolg",
        message: "Ziel wurde erfolgreich erstellt",
      });
      navigate("/goals");
    } catch (error) {
      const { type, message } = handleError(error, "Ziel erstellen");
      toastStore.add({ type, caption: "Fehler", message });
      console.error("Failed create goal:", error);
    }
  };

  const handleOwnerSelect = (userId: string) => {
    setGoal((prev) => ({
      ...prev,
      ownerId: userId,
    }));
  };

  const hasUnsavedChanges = useCallback(() => {
    return (
      goal.description !== INITIAL_GOAL_STATE.description ||
      goal.targetValue !== INITIAL_GOAL_STATE.targetValue ||
      goal.studioId !== INITIAL_GOAL_STATE.studioId ||
      goal.groupId !== INITIAL_GOAL_STATE.groupId ||
      goal.ownerId !== INITIAL_GOAL_STATE.ownerId ||
      goal.metricType !== INITIAL_GOAL_STATE.metricType ||
      goal.startDate.getTime() !== INITIAL_GOAL_STATE.startDate.getTime() ||
      goal.endDate.getTime() !== INITIAL_GOAL_STATE.endDate.getTime()
    );
  }, [goal]);

  const handleNavigation = useCallback(
    async (path: string) => {
      if (hasUnsavedChanges()) {
        const confirmed = await showConfirmationModal({
          title: "Ungespeicherte Änderungen",
          text: "Sie haben ungespeicherte Änderungen. Möchten Sie die Seite trotzdem verlassen?",
          confirmText: "Verlassen",
          cancelText: "Abbrechen",
        });
        if (confirmed) navigate(path);
      } else {
        navigate(path);
      }
    },
    [hasUnsavedChanges, navigate, showConfirmationModal]
  );

  return (
    <div className="flex h-full w-full justify-center">
      <ConfirmationModalComponent />
      <div className="flex flex-col mt-4 gap-4 w-7/12 h-full">
        <span className="text-xl font-bold">Neues Ziel erstellen</span>
        <div className="flex flex-col mt-4 px-4 border border-neutral rounded">
          <form onSubmit={handleSubmit}>
            <table className="table table-fixed w-full">
              <colgroup>
                <col className="w-4" />
                <col className="w-4" />
              </colgroup>
              <tbody>
                {/* Metric Type */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Kennzahl</span>
                  </td>
                  <td>
                    <div>
                      <select name="metricType" value={goal.metricType} onChange={handleChange} className="select select-sm select-bordered w-full rounded" required>
                        <option value={MetricType.NEW_MEMBERS}>Neumitglieder</option>
                        <option value={MetricType.CANCELLATIONS}>Kündigungen</option>
                      </select>
                    </div>
                  </td>
                </tr>

                {/* Target Value */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Zielwert</span>
                  </td>
                  <td>
                    <div>
                      <input type="number" name="targetValue" value={goal.targetValue} onChange={handleChange} className="input input-sm input-bordered w-full rounded" required min="0" />
                    </div>
                  </td>
                </tr>

                {/* Start Date */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Startdatum</span>
                  </td>
                  <td>
                    <div>
                      <input type="date" name="startDate" value={DateTime.fromJSDate(goal.startDate).toFormat("yyyy-MM-dd")} onChange={handleChange} className="input input-sm input-bordered w-full rounded" required />
                    </div>
                  </td>
                </tr>

                {/* End Date */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Enddatum</span>
                  </td>
                  <td>
                    <div>
                      <input type="date" name="endDate" value={DateTime.fromJSDate(goal.endDate).toFormat("yyyy-MM-dd")} onChange={handleChange} className="input input-sm input-bordered w-full rounded" required />
                    </div>
                  </td>
                </tr>

                {/* Studio/Group Selection */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Zuordnung</span>
                  </td>
                  <td>
                    <div className="flex flex-col gap-4">
                      {/* Studio Selection */}
                      <div className="flex flex-col gap-2">
                        <span className="text-sm font-medium">Studio</span>
                        {goal.studioId !== -1 ? (
                          <div className="badge badge-base-100 border border-neutral rounded gap-2 py-3">
                            {customerStore.getStudio(goal.studioId)?.name || "Unknown Studio"}
                            <button
                              type="button"
                              onClick={() => {
                                setGoal((prev) => ({ ...prev, studioId: -1 }));
                                setSelectedType(null);
                              }}
                              className="btn btn-ghost btn-xs p-0"
                            >
                              <XMarkIcon className="h-4 w-4" />
                            </button>
                          </div>
                        ) : (
                          <div className={`dropdown w-full bg-base-100 border border-neutral rounded ${selectedType === "group" ? "opacity-50 pointer-events-none" : ""}`}>
                            <div tabIndex={0} role="button" className="btn btn-sm btn-ghost m-1 rounded flex justify-between items-center">
                              Studio wählen
                              <ChevronDownIcon className="h-4 w-4" />
                            </div>
                            <div tabIndex={0} className="dropdown-content z-[1] menu p-2 bg-base-100 border border-neutral w-full max-h-56 overflow-y-auto">
                              <div className="flex flex-col gap-1">
                                {customerStore.studios.map((studio) => (
                                  <div
                                    key={studio.id}
                                    className="cursor-pointer hover:bg-neutral rounded-lg p-2"
                                    onClick={() => {
                                      setGoal((prev) => ({ ...prev, studioId: studio.id, groupId: "" }));
                                      setSelectedType("studio");
                                    }}
                                  >
                                    {studio.name}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {/* Group Selection */}
                      <div className="flex flex-col gap-2">
                        <span className="text-sm font-medium">Gruppe</span>
                        {goal.groupId ? (
                          <div className="badge badge-base-100 border border-neutral rounded gap-2 py-3">
                            {groupStore.groups.find((g) => g.id === goal.groupId)?.groupName || "Unknown Group"}
                            <button
                              type="button"
                              onClick={() => {
                                setGoal((prev) => ({ ...prev, groupId: "" }));
                                setSelectedType(null);
                              }}
                              className="btn btn-ghost btn-xs p-0"
                            >
                              <XMarkIcon className="h-4 w-4" />
                            </button>
                          </div>
                        ) : (
                          <div className={`dropdown w-full bg-base-100 border border-neutral rounded ${selectedType === "studio" ? "opacity-50 pointer-events-none" : ""}`}>
                            <div tabIndex={0} role="button" className="btn btn-sm btn-ghost m-1 rounded flex justify-between items-center">
                              Select Group
                              <ChevronDownIcon className="h-4 w-4" />
                            </div>
                            <div tabIndex={0} className="dropdown-content z-[1] menu p-2 bg-base-100 border border-neutral w-full max-h-56 overflow-y-auto">
                              <div className="flex flex-col gap-1">
                                {groupStore.groups.map((group) => (
                                  <div
                                    key={group.id}
                                    className="cursor-pointer hover:bg-neutral rounded-lg p-2"
                                    onClick={() => {
                                      setGoal((prev) => ({ ...prev, groupId: group.id, studioId: -1 }));
                                      setSelectedType("group");
                                    }}
                                  >
                                    {group.groupName}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>

                {/* Description */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Beschreibung</span>
                  </td>
                  <td>
                    <div>
                      <textarea name="description" value={goal.description} onChange={handleChange} className="textarea textarea-bordered w-full rounded" rows={3} />
                    </div>
                  </td>
                </tr>

                {/* OWNER */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Verantwortlich</span>
                  </td>
                  <td>
                    <div className="flex flex-col gap-2">
                      {goal.ownerId ? (
                        <div className="badge badge-base-100 border border-neutral rounded gap-2 py-3">
                          {userStore.getUserName(goal.ownerId)}
                          <button type="button" onClick={() => setGoal((prev) => ({ ...prev, ownerId: "" }))} className="btn btn-ghost btn-xs p-0">
                            <XMarkIcon className="h-4 w-4" />
                          </button>
                        </div>
                      ) : (
                        <div className="dropdown w-full bg-base-100 border border-neutral rounded">
                          <div tabIndex={0} role="button" className="btn btn-sm btn-ghost m-1 rounded flex justify-between items-center">
                            Person wählen
                            <ChevronDownIcon className="h-4 w-4" />
                          </div>
                          <div tabIndex={0} className="dropdown-content z-[1] menu p-2 bg-base-100 border border-neutral w-full max-h-56 overflow-y-auto">
                            <div className="flex flex-col gap-1">
                              {userStore.users.map((user) => (
                                <div key={user.id} className="cursor-pointer hover:bg-neutral rounded-lg p-2" onClick={() => handleOwnerSelect(user.id)}>
                                  {user.firstname} {user.lastname}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>

                {/* Controls */}
                <tr>
                  <td colSpan={2}>
                    <div className="flex gap-4 py-4">
                      <button type="button" className="btn btn-neutral" onClick={() => handleNavigation("/goals")}>
                        Abbrechen
                      </button>
                      <button type="submit" className="btn btn-primary" disabled={!goal.targetValue || !goal.startDate || !goal.endDate || !goal.ownerId || ((!goal.studioId || goal.studioId === -1) && !goal.groupId)}>
                        Ziel erstellen
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
});

export default CreateGoalPage;
