export const API_CONFIG = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL || "http://localhost:10901",
  ENDPOINTS: {
    // AUTH
    LOGIN: "/auth/login",
    ROLES_ALL: "/auth/get-all-roles",
    // USERS
    USERS_CURRENT: "/auth/get-current-user",
    USERS_ALL: "/users/customer",
    USERS_GET: "/users/get",
    USERS_CREATE: "/users/create",
    USERS_UPDATE: "/users/update",
    USERS_DELETE: "/users/delete",
    USERS_CHANGE_PASSWORD: "/users/change-password",
    // CUSTOMERS
    CUSTOMERS_GET: "/customers/get",
    // GROUPS
    GROUPS_ALL: "/groups/all",
    GROUPS_GET: "/groups/get",
    GROUPS_CREATE: "/groups/create",
    GROUPS_UPDATE: "/groups/update",
    GROUPS_DELETE: "/groups/delete",
    // GOALS
    GOALS_ALL: "/goals/all",
    GOALS_GET: "/goals/get",
    GOALS_CREATE: "/goals/create",
    GOALS_UPDATE: "/goals/update",
    GOALS_DELETE: "/goals/delete",
    // TASKS
    TASKS_GET: "/tasks/goal",
    TASKS_FOR_USER_GET: "/tasks/user",
    TASKS_CREATE: "/tasks/goal",
    TASKS_UPDATE: "/tasks",
    TASKS_DELETE: "/tasks",
    TASKS_TOGGLE: "/tasks",
    // METRICS
    METRICS_NEW_MEMBERS: "/metrics/new-members",
    METRICS_CANCELLATIONS: "/metrics/cancellations",
  },
};
