import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { rootStore } from "../stores/RootStore";
import { toastStore } from "../components/ToastContainer";
import { handleError } from "../errorHandler";

const LoginPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authStore } = rootStore;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const from = location.state?.from?.pathname || "/";

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await authStore.login(email, password);
      navigate(from, { replace: true });
    } catch (error: any) {
      const { type, message } = handleError(error, "Anmelden");
      toastStore.add({ type, caption: "Fehler", message });
      console.error("Failed to login:", error);
    }
  };

  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content flex-col lg:flex-row-reverse border border-neutral">
        <div className="card flex-shrink-0 w-[28rem]">
          <div className="flex flex-col w-full items-center justify-center gap-8">
            <img src="/logo.png" alt="Logo" className="h-auto w-32 pt-6" />
            <span className="text-xl">Welcome to StudioOS</span>
          </div>
          <form onSubmit={handleLogin} className="card-body">
            <div className="form-control">
              <label htmlFor="email" className="label">
                <span className="label-text">Email</span>
              </label>
              <input id="email" type="email" placeholder="Email" className="input input-bordered" value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <div className="form-control">
              <label htmlFor="password" className="label">
                <span className="label-text">Password</span>
              </label>
              <input id="password" type="password" placeholder="Password" className="input input-bordered" value={password} onChange={(e) => setPassword(e.target.value)} required />
            </div>
            <div className="form-control mt-6">
              <button type="submit" className="btn btn-primary">
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default LoginPage;
