import React from "react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

interface RefreshButtonProps {
  onClick: () => void;
  showLabel?: boolean; // Optional prop für das Label
}

const RefreshButton: React.FC<RefreshButtonProps> = ({
  onClick,
  showLabel = true, // Default: Label wird angezeigt
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const icon = event.currentTarget.querySelector(".refresh-icon");
    if (icon) {
      icon.classList.remove("spin-once");
      setTimeout(() => icon.classList.add("spin-once"), 0);
    }
    onClick();
  };

  return (
    <>
      <style>
        {`
          @keyframes spin {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          }
          .spin-once {
            animation: spin 0.5s linear;
          }
        `}
      </style>
      <button onClick={handleClick} className="btn btn-ghost flex items-center">
        <ArrowPathIcon className={`h-4 w-4 refresh-icon ${showLabel ? "mr-2" : ""}`} />
        {showLabel && "Refresh"}
      </button>
    </>
  );
};

export default RefreshButton;
