import { observer } from "mobx-react-lite";
import React from "react";
import { useConfirmationModal } from "../components/ConfirmationModal";
import PasswordChangeComponent from "../components/PasswordChangeComponent";
import { rootStore } from "../stores/RootStore";

const ProfilePage: React.FC = observer(() => {
  const { ConfirmationModalComponent } = useConfirmationModal();
  const { authStore } = rootStore;

  return (
    <div className="w-full h-fit flex justify-center">
      <ConfirmationModalComponent />
      <div className="flex flex-col mt-4 gap-4 w-7/12">
        <div className="flex justify-between items-center">
          <span className="text-2xl font-bold">Profil</span>
        </div>
        <div className="border border-neutral rounded p-4">
          <PasswordChangeComponent isSelfChange={true} userId={authStore.user?.id || ""} />
        </div>
      </div>
    </div>
  );
});

export default ProfilePage;
