import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GroupDTO, StudioDTO } from "../backend-types";
import { useConfirmationModal } from "../components/ConfirmationModal";
import { toastStore } from "../components/ToastContainer";
import { rootStore } from "../stores/RootStore";
import { handleError } from "../errorHandler";

const EditGroupPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { groupStore, customerStore } = rootStore;
  const { showConfirmationModal, ConfirmationModalComponent } = useConfirmationModal();

  const [group, setGroup] = useState<GroupDTO>({
    id: "",
    groupName: "",
    userIds: [],
    studioIds: [],
  });
  const [selectedStudios, setSelectedStudios] = useState<StudioDTO[]>([]);
  const [availableStudios, setAvailableStudios] = useState<StudioDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      if (!id) {
        toastStore.add({
          type: "error",
          caption: "Fehler",
          message: "Keine Gruppen-ID gefunden.",
        });
        navigate("/groups");
        return;
      }

      setIsLoading(true);
      try {
        await customerStore.refreshCustomer();
        const groupData = await groupStore.getGroup(id);
        setGroup(groupData);

        const selectedStudios = customerStore.studios.filter((studio) => groupData.studioIds.includes(studio.id));
        setSelectedStudios(selectedStudios);

        const availableStudios = customerStore.studios.filter((studio) => !groupData.studioIds.includes(studio.id));
        setAvailableStudios(availableStudios);
      } catch (error) {
        const { type, message } = handleError(error, "Gruppe laden");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to load group:", error);
        navigate("/groups");
      } finally {
        setIsLoading(false);
      }
    };

    initializeData();
  }, [id, customerStore, groupStore, navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setGroup((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!id) return;

    try {
      await groupStore.updateGroup({
        id: id,
        groupName: group.groupName,
        studioIds: selectedStudios.map((studio) => studio.id),
      });

      toastStore.add({
        type: "success",
        caption: "Erfolg",
        message: "Gruppe wurde erfolgreich aktualisiert",
      });
      navigate("/groups");
    } catch (error: any) {
      const { type, message } = handleError(error, "Gruppe aktualisieren");
      toastStore.add({ type, caption: "Fehler", message });
      console.error("Failed to edit group:", error);
    }
  };

  const handleStudioCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, studioId: number) => {
    event.preventDefault();
    event.stopPropagation();

    const studio = availableStudios.find((s) => s.id === studioId);
    if (!studio) return;
    setSelectedStudios((prev) => [...prev, studio]);
    setAvailableStudios((prev) => prev.filter((s) => s.id !== studioId));
  };

  const handleRemoveStudio = (studioId: number) => {
    const studio = selectedStudios.find((s) => s.id === studioId);
    if (!studio) return;

    setSelectedStudios((prev) => prev.filter((s) => s.id !== studioId));
    setAvailableStudios((prev) => [...prev, studio]);
  };

  const handleNavigation = useCallback(
    async (path: string) => {
      const originalGroup = await groupStore.getGroup(id || "");
      const hasUnsavedChanges =
        group.groupName !== originalGroup?.groupName ||
        selectedStudios
          .map((s) => s.id)
          .sort()
          .join(",") !== originalGroup?.studioIds.sort().join(",");

      if (hasUnsavedChanges) {
        const confirmed = await showConfirmationModal({
          title: "Ungespeicherte Änderungen",
          text: "Sie haben ungespeicherte Änderungen. Möchten Sie die Seite trotzdem verlassen?",
          confirmText: "Verlassen",
          cancelText: "Abbrechen",
        });
        if (confirmed) navigate(path);
      } else {
        navigate(path);
      }
    },
    [group, selectedStudios, navigate, showConfirmationModal, id, groupStore]
  );

  if (isLoading) {
    return (
      <div className="flex h-full w-full justify-center items-center">
        <span>Loading...</span>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full justify-center">
      <ConfirmationModalComponent />
      <div className="flex flex-col mt-4 gap-4 w-7/12 h-full">
        <span className="text-xl font-bold">Edit group</span>
        <div className="flex flex-col mt-4 px-4 border border-neutral rounded">
          <form onSubmit={handleSubmit}>
            <table className="table table-fixed w-full">
              <colgroup>
                <col className="w-4" />
                <col className="w-4" />
              </colgroup>
              <thead>
                <tr className="border-none">
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {/* Group Name */}
                <tr key={"group"} className="border-b border-neutral">
                  <td>
                    <span>Group</span>
                  </td>
                  <td>
                    <div>
                      <label className="label">
                        <span className="label-text">Group Name</span>
                      </label>
                      <input type="text" name="groupName" value={group.groupName} onChange={handleChange} className="input input-sm input-bordered w-full rounded" />
                    </div>
                  </td>
                </tr>
                {/* Studios */}
                <tr key={"studios"} className="border-b border-neutral">
                  <td>
                    <span>Studio(s)</span>
                  </td>
                  <td>
                    <div className="flex flex-col gap-2">
                      {/* Selected Studios Badges */}
                      <div className="flex flex-wrap gap-2">
                        {selectedStudios.map((studio) => (
                          <div key={studio.id} className="badge badge-base-100 border border-neutral rounded gap-2 py-3">
                            {studio.name}
                            <button type="button" onClick={() => handleRemoveStudio(studio.id)} className="btn btn-ghost btn-xs p-0">
                              <XMarkIcon className="h-4 w-4" />
                            </button>
                          </div>
                        ))}
                      </div>
                      {/* Dropdown */}
                      {availableStudios.length > 0 && (
                        <div className="flex flex-col gap-2">
                          <div className="relative" ref={dropdownRef}>
                            <button type="button" onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="btn btn-sm btn-ghost w-full bg-base-100 border border-neutral rounded flex justify-between items-center">
                              Studios wählen
                              <ChevronDownIcon className="h-4 w-4" />
                            </button>
                            {isDropdownOpen && (
                              <div className="absolute z-50 w-full mt-1 bg-base-100 border border-neutral rounded shadow-lg max-h-56 overflow-y-auto">
                                <div className="p-2">
                                  {availableStudios.map((studio) => (
                                    <label key={studio.id} className="flex items-center gap-2 px-2 py-1 hover:bg-neutral rounded cursor-pointer">
                                      <input type="checkbox" className="checkbox checkbox-sm" onChange={(e) => handleStudioCheckboxChange(e, studio.id)} />
                                      <span className="text-sm">{studio.name}</span>
                                    </label>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
                {/* Controls */}
                <tr key={"controls"}>
                  <td>
                    <div className="flex gap-4">
                      <button type="button" className="btn btn-neutral" onClick={() => handleNavigation("/groups")}>
                        <span>Cancel</span>
                      </button>
                      <button type="submit" className="btn btn-primary" disabled={!group.groupName}>
                        <span>Save Changes</span>
                      </button>
                    </div>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
});

export default EditGroupPage;
