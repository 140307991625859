import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CartesianGrid, Cell, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { StudioCancellationMetricsProjection, StudioMetricsProjection } from "../backend-types";
import TaskTable from "../components/TaskTable";
import { MetricType } from "../stores/GoalStore";
import { rootStore } from "../stores/RootStore";
import { handleError } from "../errorHandler";
import { toastStore } from "../components/ToastContainer";

type MetricsProjection = StudioMetricsProjection | StudioCancellationMetricsProjection;

const GoalDetailsPage = observer(() => {
  const { id } = useParams();
  const { goalStore, taskStore, userStore, authStore } = rootStore;
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [newTaskTitle, setNewTaskTitle] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      userStore.refreshUsers();
      goalStore.refreshGoals();
      taskStore.loadTasksForGoal(id);
    }
  }, [id, goalStore, taskStore, userStore]);

  useEffect(() => {
    if (isAddingTask && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddingTask]);

  const handleAddTask = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && newTaskTitle.trim() && id) {
      try {
        await taskStore.createTask(id, newTaskTitle, authStore.user!.id || "");
        setNewTaskTitle("");
        setIsAddingTask(false);
      } catch (error) {
        const { type, message } = handleError(error, "Aufgabe erstellen");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to create task:", error);
      }
    } else if (e.key === "Escape") {
      setNewTaskTitle("");
      setIsAddingTask(false);
    }
  };

  const handleBlur = () => {
    if (!newTaskTitle.trim()) {
      setIsAddingTask(false);
    }
  };

  const handleToggleTask = async (taskId: string) => {
    if (id) {
      try {
        await taskStore.toggleTaskCompletion(id, taskId);
      } catch (error) {
        const { type, message } = handleError(error, "Aufgabenstatus ändern");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to toggle task:", error);
      }
    }
  };

  const handleDeleteTask = async (taskId: string) => {
    if (id) {
      try {
        await taskStore.deleteTask(id, taskId);
      } catch (error) {
        const { type, message } = handleError(error, "Aufgabe löschen");
        toastStore.add({ type, caption: "Fehler", message });
        console.error("Failed to delete task:", error);
      }
    }
  };

  const goal = goalStore.goals.find((g) => g.id === id);
  const metricsState = goal ? goalStore.getMetricsState(goal.id) : null;
  const tasks = id ? taskStore.getTasksForGoal(id) : [];
  const isLoadingTasks = id ? taskStore.isLoadingForGoal(id) : false;

  if (!goal || !metricsState || metricsState.isLoading || isLoadingTasks) {
    return (
      <div className="flex h-full w-full justify-center items-center">
        <div className="loading loading-spinner loading-lg"></div>
      </div>
    );
  }

  const metrics = metricsState.rawData || ([] as MetricsProjection[]);
  const currentValue = metrics.length > 0 ? metrics[metrics.length - 1].runningTotal : 0;

  // Calculate time progress with proper date handling
  const startDate = goal.startDate ? DateTime.fromJSDate(new Date(goal.startDate)) : DateTime.fromJSDate(metrics[0]?.date || new Date());
  const endDate = DateTime.fromJSDate(new Date(goal.endDate));
  const now = DateTime.now();

  const totalDays = Math.max(endDate.diff(startDate, "days").days, 1);
  const elapsedDays = Math.max(Math.min(now.diff(startDate, "days").days, totalDays), 0);
  const timeProgress = (elapsedDays / totalDays) * 100;

  // Calculate daily averages based on metric type
  const currentDaily = (() => {
    if (metrics.length === 0) return 0;

    switch (goal.metricType) {
      case MetricType.NEW_MEMBERS:
        return (metrics as StudioMetricsProjection[]).reduce((sum, metric) => sum + metric.averageDailyCount, 0) / metrics.length;

      case MetricType.CANCELLATIONS:
        return (metrics as StudioCancellationMetricsProjection[]).reduce((sum, metric) => sum + metric.dailyCount, 0) / metrics.length;

      default:
        return 0;
    }
  })();

  // Calculate goal progress based on metric type
  const goalProgress = (() => {
    switch (goal.metricType) {
      case MetricType.CANCELLATIONS:
        return currentValue <= goal.targetValue ? 100 : Math.max(0, (goal.targetValue / currentValue) * 100);

      default:
        return (currentValue / goal.targetValue) * 100;
    }
  })();

  // Calculate required daily average based on metric type
  const remainingDays = Math.max(totalDays - elapsedDays, 1);
  const requiredDaily = (() => {
    switch (goal.metricType) {
      case MetricType.CANCELLATIONS:
        return currentValue >= goal.targetValue ? 0 : (goal.targetValue - currentValue) / remainingDays;

      default:
        return (goal.targetValue - currentValue) / remainingDays;
    }
  })();

  // Calculate projection based on metric type
  const projectedValue = (() => {
    switch (goal.metricType) {
      case MetricType.CANCELLATIONS:
        return currentDaily * totalDays;

      default:
        return currentDaily * totalDays;
    }
  })();

  const formatDate = (date: Date | string) => {
    return DateTime.fromJSDate(new Date(date)).toLocaleString({
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  return (
    <div className="flex h-full w-full justify-center">
      <div className="flex flex-col mt-4 gap-4 w-10/12 h-full">
        {/* Header */}
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold">{goal.description}</h1>
            <p className="text-sm opacity-70">
              {formatDate(goal.startDate)} - {formatDate(goal.endDate)}
            </p>
          </div>
        </div>

        {/* Metrics Cards */}
        <div className="grid grid-cols-3 gap-4">
          {/* Gauge */}
          <div className="border border-neutral rounded p-4">
            <div className="font-bold mb-4">Gesamtfortschritt</div>
            <div className="flex justify-center items-center h-64">
              <PieChart width={200} height={100}>
                <Pie
                  data={[
                    { name: "Progress", value: goalProgress },
                    { name: "Remaining", value: Math.max(0, 100 - goalProgress) },
                  ]}
                  cx={100}
                  cy={100}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={0}
                  dataKey="value"
                >
                  <Cell fill="#2563eb" />
                  <Cell fill="#e5e7eb" />
                </Pie>
                <text x={100} y={90} textAnchor="middle" dominantBaseline="middle" className="text-2xl font-bold">
                  {goalProgress.toFixed(1)}%
                </text>
              </PieChart>
            </div>
          </div>

          {/* Progress Chart */}
          <div className="border border-neutral rounded p-4">
            <div className="font-bold mb-4">Fortschrittsverlauf</div>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={metrics}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
                  <XAxis dataKey="date" tickFormatter={(date) => DateTime.fromJSDate(new Date(date)).toFormat("dd.MM")} tick={{ fontSize: 11 }} />
                  <YAxis tick={{ fontSize: 11 }} />
                  <Tooltip formatter={(value) => [value, "Wert"]} labelFormatter={(date) => DateTime.fromJSDate(new Date(date)).toFormat("dd.MM.yyyy")} contentStyle={{ fontSize: "11px" }} />
                  <Line type="monotone" dataKey="runningTotal" stroke="#2563eb" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Progress vs Time */}
          <div className="border border-neutral rounded p-4">
            <div className="font-bold mb-4">Fortschritt vs. Zeit</div>
            <div className="space-y-4">
              <div>
                <div className="flex justify-between mb-1">
                  <span>Zeitfortschritt</span>
                  <span>{timeProgress.toFixed(1)}%</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div className="bg-primary h-2 rounded-full" style={{ width: `${timeProgress}%` }} />
                </div>
              </div>
              <div>
                <div className="flex justify-between mb-1">
                  <span>Zielfortschritt</span>
                  <span>{goalProgress.toFixed(1)}%</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div className="bg-primary h-2 rounded-full" style={{ width: `${goalProgress}%` }} />
                </div>
              </div>
              <div className="pt-4 border-t border-neutral">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm font-medium">Tagesdurchschnitt Ist</p>
                    <p className="text-xl font-bold">{currentDaily.toFixed(1)}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium">Tagesdurchschnitt Soll</p>
                    <p className="text-xl font-bold">{requiredDaily.toFixed(1)}</p>
                  </div>
                </div>
              </div>
              <div className="pt-4 border-t border-neutral">
                <p className="text-sm font-medium">Prognose zum Zielende</p>
                <p className="text-xl font-bold">{projectedValue.toFixed(0)}</p>
              </div>
            </div>
          </div>
        </div>

        {/* TASK TABLE */}
        <div className="flex-1 min-h-0 mb-4">
          <TaskTable
            tasks={tasks}
            onToggleTask={handleToggleTask}
            getUserName={(userId: string) => userStore.getUserName(userId)}
            showAddButton={true}
            onEditTask={(taskId: string) => navigate(`/goals/task/edit/${goal.id}/${taskId}`)}
            onDeleteTask={handleDeleteTask}
            getGoalName={() => goal.description}
            goalId={goal.id}
            addTaskComponent={
              isAddingTask ? (
                <div className="flex items-center gap-2">
                  <input type="checkbox" checked={false} disabled className="checkbox checkbox-sm opacity-50" />
                  <input
                    ref={inputRef}
                    type="text"
                    value={newTaskTitle}
                    onChange={(e) => setNewTaskTitle(e.target.value)}
                    onKeyDown={handleAddTask}
                    onBlur={handleBlur}
                    placeholder="Neue Aufgabe eingeben... (Enter zum Speichern)"
                    className="w-full bg-transparent border-none focus:outline-none"
                  />
                </div>
              ) : undefined
            }
            onAddTask={() => setIsAddingTask(true)}
          />
        </div>
      </div>
    </div>
  );
});

export default GoalDetailsPage;
