import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

interface SearchBarProps {
  onSearch: (term: string) => void;
  placeholder?: string;
  paramName?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch, placeholder = "Suchen", paramName = "q" }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchTerm, setSearchTerm] = useState(() => {
    const initialTerm = searchParams.get(paramName) || "";
    if (initialTerm) {
      onSearch(initialTerm);
    }
    return initialTerm;
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTerm = e.target.value;
    setSearchTerm(newTerm);
    onSearch(newTerm);

    if (newTerm) {
      searchParams.set(paramName, newTerm);
    } else {
      searchParams.delete(paramName);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <label className="input input-bordered flex items-center gap-2">
      <MagnifyingGlassIcon className="size-4" />
      <input type="text" className="grow" placeholder={placeholder} value={searchTerm} onChange={handleChange} />
    </label>
  );
};

export default SearchBar;
