import { Navigate, Outlet, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { rootStore } from "../stores/RootStore";

interface ProtectedRouteProps {
  requiredRole?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = observer(({ requiredRole }) => {
  const { authStore } = rootStore;
  const location = useLocation();

  if (authStore.isLoading) {
    return <span className="loading loading-ring loading-lg"></span>;
  }

  if (!authStore.isAuthenticated) {
    // Redirect to login page, but save the location they tried to access
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (requiredRole && !authStore.hasRole(requiredRole)) {
    // User is authenticated but doesn't have the required role
    return <Navigate to="/unauthorized" replace />;
  }

  return <Outlet />;
});

export default ProtectedRoute;
