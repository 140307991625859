import { ArrowsPointingInIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import SidebarHeader from "./SidebarHeader";
import SidebarSettingsControl from "./SidebarSettingsControl";
import SidebarUserControl from "./SidebarUserControl";

const Sidebar: React.FC = () => {
  const navItems = [
    { name: "Ziele", path: "/goals", icon: ArrowsPointingInIcon },
    { name: "Meine Aufgaben", path: "/my-tasks", icon: CheckCircleIcon },
  ];

  return (
    <div className="flex flex-col h-screen w-64 bg-base-100 text-base-content border-e-2 border-neutral">
      <SidebarHeader />
      <nav className="flex-1 mx-4 mt-10">
        <ul>
          {navItems.map((item) => (
            <li key={item.name}>
              <Link to={item.path} className="flex gap-2 items-center py-2 px-4 hover:bg-neutral rounded text-sm">
                <item.icon className="w-6 h-6" aria-hidden="true" />
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <SidebarSettingsControl />
      <SidebarUserControl />
    </div>
  );
};

export default Sidebar;
