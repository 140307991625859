import { AuthStore } from "./AuthStore";
import { CustomerStore } from "./CustomerStore";
import { GoalStore } from "./GoalStore";
import { GroupStore } from "./GroupStore";
import { TaskStore } from "./TaskStore";
import { UserStore } from "./UserStore";

export class RootStore {
  authStore: AuthStore;
  userStore: UserStore;
  customerStore: CustomerStore;
  groupStore: GroupStore;
  goalStore: GoalStore;
  taskStore: TaskStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.userStore = new UserStore(this);
    this.customerStore = new CustomerStore(this);
    this.groupStore = new GroupStore(this);
    this.goalStore = new GoalStore(this);
    this.taskStore = new TaskStore(this);
  }
}

export const rootStore = new RootStore();
