import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TaskDTO } from "../backend-types";
import { rootStore } from "../stores/RootStore";
import { useConfirmationModal } from "../components/ConfirmationModal";
import { toastStore } from "../components/ToastContainer";
import { XMarkIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { handleError } from "../errorHandler";

export const EditTaskPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const { goalId, taskId } = useParams();
  const { taskStore, userStore, goalStore } = rootStore;
  const { showConfirmationModal, ConfirmationModalComponent } = useConfirmationModal();

  const [task, setTask] = useState<TaskDTO | null>(null);
  const [originalTask, setOriginalTask] = useState<TaskDTO | null>(null);

  useEffect(() => {
    userStore.refreshUsers();
    if (goalId) {
      goalStore.refreshGoals();
      const currentTask = taskStore.getTasksForGoal(goalId).find((t) => t.id === taskId);
      if (currentTask) {
        setTask(currentTask);
        setOriginalTask(currentTask);
      }
    }
  }, [goalId, taskId, userStore, goalStore, taskStore]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (!task) return;

    setTask({
      ...task,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!goalId || !taskId || !task) return;

    try {
      await taskStore.updateTask(goalId, taskId, {
        title: task.title,
        ownerId: task.ownerId,
      });
      toastStore.add({
        type: "success",
        caption: "Erfolg",
        message: "Aufgabe wurde erfolgreich aktualisiert",
      });
      navigate(`/goals/details/${goalId}`);
    } catch (error) {
      const { type, message } = handleError(error, "Aufgabe aktualisieren");
      toastStore.add({ type, caption: "Fehler", message });
      console.error("Failed to update task:", error);
    }
  };

  const handleOwnerSelect = (userId: string) => {
    if (!task) return;
    setTask({
      ...task,
      ownerId: userId,
    });
  };

  const hasUnsavedChanges = useCallback(() => {
    if (!task || !originalTask) return false;
    return task.title !== originalTask.title || task.ownerId !== originalTask.ownerId;
  }, [task, originalTask]);

  const handleNavigation = useCallback(
    async (path: string) => {
      if (hasUnsavedChanges()) {
        const confirmed = await showConfirmationModal({
          title: "Ungespeicherte Änderungen",
          text: "Sie haben ungespeicherte Änderungen. Möchten Sie die Seite trotzdem verlassen?",
          confirmText: "Verlassen",
          cancelText: "Abbrechen",
        });
        if (confirmed) navigate(path);
      } else {
        navigate(path);
      }
    },
    [hasUnsavedChanges, navigate, showConfirmationModal]
  );

  const goal = goalStore.goals.find((g) => g.id === goalId);
  if (!goal || !task) return null;

  return (
    <div className="flex h-full w-full justify-center">
      <ConfirmationModalComponent />
      <div className="flex flex-col mt-4 gap-4 w-7/12 h-full">
        <span className="text-xl font-bold">Aufgabe bearbeiten</span>
        <div className="flex flex-col mt-4 px-4 border border-neutral rounded">
          <form onSubmit={handleSubmit}>
            <table className="table table-fixed w-full">
              <colgroup>
                <col className="w-4" />
                <col className="w-4" />
              </colgroup>
              <tbody>
                {/* Title */}
                <tr className="border-b border-neutral">
                  <td className="w-4">
                    <span>Titel</span>
                  </td>
                  <td>
                    <div>
                      <textarea name="title" value={task.title} onChange={handleChange} className="textarea textarea-bordered w-full rounded" rows={3} required />
                    </div>
                  </td>
                </tr>

                {/* Goal - Read Only */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Ziel</span>
                  </td>
                  <td>
                    <div className="text-neutral-500">{goal.description}</div>
                  </td>
                </tr>

                {/* Owner */}
                <tr className="border-b border-neutral">
                  <td>
                    <span>Verantwortlich</span>
                  </td>
                  <td>
                    <div className="flex flex-col gap-2">
                      {task.ownerId ? (
                        <div className="badge badge-base-100 border border-neutral rounded gap-2 py-3">
                          {userStore.getUserName(task.ownerId)}
                          <button type="button" onClick={() => setTask({ ...task, ownerId: "" })} className="btn btn-ghost btn-xs p-0">
                            <XMarkIcon className="h-4 w-4" />
                          </button>
                        </div>
                      ) : (
                        <div className="dropdown w-full bg-base-100 border border-neutral rounded">
                          <div tabIndex={0} role="button" className="btn btn-sm btn-ghost m-1 rounded flex justify-between items-center">
                            Person wählen
                            <ChevronDownIcon className="h-4 w-4" />
                          </div>
                          <div tabIndex={0} className="dropdown-content z-[1] menu p-2 bg-base-100 border border-neutral w-full max-h-56 overflow-y-auto">
                            <div className="flex flex-col gap-1">
                              {userStore.users.map((user) => (
                                <div key={user.id} className="cursor-pointer hover:bg-neutral rounded-lg p-2" onClick={() => handleOwnerSelect(user.id)}>
                                  {user.firstname} {user.lastname}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>

                {/* Controls */}
                <tr>
                  <td colSpan={2}>
                    <div className="flex gap-4 py-4">
                      <button type="button" className="btn btn-neutral" onClick={() => handleNavigation(`/goals/details/${goalId}`)}>
                        Abbrechen
                      </button>
                      <button type="submit" className="btn btn-primary" disabled={!hasUnsavedChanges()}>
                        Änderungen speichern
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
});
