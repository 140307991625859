import { makeAutoObservable, runInAction } from "mobx";
import { RootStore } from "./RootStore";
import { apiService } from "../ApiService";
import { API_CONFIG } from "../config/api.config";
import { LOCAL_STORAGE_CONFIG } from "../config/localstorage.config";
import { RoleModel } from "../models/RoleModel";
import { UserInformationDTO } from "../backend-types";

export class AuthStore {
  user: UserInformationDTO | null = null;
  token: string | null = null;
  isAuthenticated: boolean = false;
  isLoading: boolean = true;
  roles: RoleModel[] = [];

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
    this.fetchRoles();
    this.initializeAuth();
  }

  hasRole(role: string) {
    return this.user?.roles?.includes(role) ?? false;
  }

  setUser(user: UserInformationDTO | null) {
    this.user = user;
    this.isAuthenticated = !!user;

    if (user?.customer) {
      this.rootStore.customerStore.initializeCustomer(user.customer);
    } else {
      this.rootStore.customerStore.clear();
    }
  }

  setToken(token: string | null) {
    this.token = token;
    if (token) {
      localStorage.setItem(LOCAL_STORAGE_CONFIG.TOKENS.JWT_TOKEN, token);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_CONFIG.TOKENS.JWT_TOKEN);
    }
  }

  async login(email: string, password: string) {
    try {
      const response = await apiService.post<{ token: string; user: UserInformationDTO }>(API_CONFIG.ENDPOINTS.LOGIN, { email, password });

      if (response.user.customer === null) {
        throw new Error("Der Account ist keinem Kundenportal zugewiesen.");
      }

      this.setToken(response.token);
      this.setUser(response.user);
    } catch (error: any) {
      console.error("Login fehler:", error);
      if (error.status === 401) throw new Error("Email oder Passwort inkorrekt.");
      throw error;
    }
  }

  async logout() {
    try {
      // await apiService.post(API_CONFIG.ENDPOINTS.LOGOUT);
    } catch (error) {
      console.error("Logout fehler:", error);
    } finally {
      this.setToken(null);
      this.setUser(null);
    }
  }

  async fetchRoles() {
    try {
      const roles = await apiService.get<RoleModel[]>(API_CONFIG.ENDPOINTS.ROLES_ALL);
      runInAction(() => {
        this.roles = roles;
      });
    } catch (error) {
      runInAction(() => {
        this.roles = [];
      });
    }
  }

  async initializeAuth() {
    const token = localStorage.getItem(LOCAL_STORAGE_CONFIG.TOKENS.JWT_TOKEN);
    if (token) {
      this.setToken(token);
      try {
        const user = await apiService.get<UserInformationDTO>(API_CONFIG.ENDPOINTS.USERS_CURRENT);
        this.setUser(user);
      } catch (error) {
        this.setToken(null);
      }
    }
    runInAction(() => {
      this.isLoading = false;
    });
  }
}
